<template>
  <div
    :class="theme == 'holiday' ? 'input-box-mobile-sys' : 'input-box-mobile'"
  >
    <el-input
      ref="inputRef"
      v-model="search"
      class="input"
      placeholder="请输入搜索内容"
      @change="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
    >
      <template slot="append">
        <span @click="handleSearch">搜索</span>
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    onlySearch: Boolean,
  },
  computed: {
    search: {
      get () {
        return this.value;
      },
      set (val) {
        this.$emit("input", val);
      },
    },
    theme () {
      return this.$store.state.systemTheme;
    }
  },
  methods: {
    handleChange () {
      if (this.onlySearch) return;
      this.$emit("search", this.search);
    },
    handleSearch () {
      this.$emit("search", this.search);
    },
    handleFocus () {
      this.$emit("focus");
    },
    handleBlur () {
      this.$emit("blur");
    },
    blur () {
      this.$refs.inputRef.blur();
    },
  },
};
</script>

<style lang="less">
.input-box-mobile {
  width: 100%;
  .input {
    width: 100%;
    height: 80/75rem;
    background: #f8f8fa;
    border-radius: 8/75rem;
    .el-input__inner {
      height: 100%;
      background: #f8f8fa;
      border: none;
      font-size: 24/75rem;
      padding: 0 120/75rem 0 30/75rem;
      border-radius: 8/75rem;
      &::placeholder {
        color: #909399;
      }
    }
    .el-input-group__append {
      border: none;
      width: 104/75rem;
      height: 64/75rem;
      background: #f9680d;
      border-radius: 0/75rem 6/75rem 6/75rem 0/75rem;
      color: #ffffff;
      text-align: center;
      font-size: 24/75rem;
      font-weight: 600;
      box-sizing: border-box;
      cursor: pointer;
      position: absolute;
      right: 8/75rem;
      top: 8/75rem;
      border-radius: 4/75rem;
      padding: 0;

      span {
        display: inline-block;
        width: 100%;
        height: 64/75rem;
        line-height: 64/75rem;
      }
    }
  }
}
.input-box-mobile-sys {
  width: 100%;
  .input {
    width: 100%;
    height: 80/75rem;
    background: #f8f8fa;
    border-radius: 8/75rem;
    .el-input__inner {
      height: 100%;
      background: #f8f8fa;
      border: none;
      font-size: 24/75rem;
      padding: 0 120/75rem 0 30/75rem;
      border-radius: 8/75rem;
      &::placeholder {
        color: #909399;
      }
    }
    .el-input-group__append {
      border: none;
      width: 104/75rem;
      height: 64/75rem;
      background: #dc2c28;
      border-radius: 0/75rem 6/75rem 6/75rem 0/75rem;
      color: #ffffff;
      text-align: center;
      font-size: 24/75rem;
      font-weight: 600;
      box-sizing: border-box;
      cursor: pointer;
      position: absolute;
      right: 8/75rem;
      top: 8/75rem;
      border-radius: 4/75rem;
      padding: 0;

      span {
        display: inline-block;
        width: 100%;
        height: 64/75rem;
        line-height: 64/75rem;
      }
    }
  }
}
</style>
