var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.handleMore),expression:"handleMore"}],staticClass:"fixed-page-mobile culture-collection-index-mobile",attrs:{"infinite-scroll-distance":200}},[_c('div',{class:`container ${_vm.bgClass}`},[_c('SearchInput',{on:{"search":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('h1',{staticClass:"title"},[_vm._v("人艺剧目典藏")]),_c('p',{staticClass:"desc"},[_vm._v("Repertory of human art")]),_c('div',{staticClass:"date-list"},_vm._l((_vm.dateList),function(dateItem){return _c('div',{key:dateItem.year,staticClass:"date-list-item",attrs:{"id":`year-${dateItem.year}`}},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(dateItem.year))]),(dateItem.children && dateItem.children.length)?_c('ul',{staticClass:"list",style:({
            height:
              dateItem.children.length % 2 === 0
                ? `${((dateItem.children.length / 2) * 486 + 110) / 75}rem`
                : `${
                    (((dateItem.children.length - 1) / 2) * 486 + 456) / 75
                  }rem`,
          })},_vm._l((dateItem.children),function(item){return _c('li',{key:item.id,staticClass:"list-item-box",on:{"click":function($event){return _vm.handleDetail(item)}}},[_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"left"},[_c('c-image',{attrs:{"src":item.image}})],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"info"},[_c('p',{staticClass:"line-name"},[_vm._v(" "+_vm._s(item.repertoireName)+" ")]),_c('p',{staticClass:"line-time"},[_vm._v(" "+_vm._s(item.showTime ? _vm.dayjs(item.showTime).format("YYYY-MM-DD") : "")+" ")])])])]),_vm._m(0,true)])}),0):_vm._e()])}),0)],1),_c('PageFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float-line"},[_c('div',{staticClass:"dot"})])
}]

export { render, staticRenderFns }