<template>
  <div
    class="fixed-page-mobile culture-collection-index-mobile"
    v-infinite-scroll="handleMore"
    :infinite-scroll-distance="200"
  >
    <div :class="`container ${bgClass}`">
      <SearchInput v-model="search" @search="handleSearch" />
      <h1 class="title">人艺剧目典藏</h1>
      <p class="desc">Repertory of human art</p>
      <div class="date-list">
        <div
          v-for="dateItem in dateList"
          :key="dateItem.year"
          class="date-list-item"
          :id="`year-${dateItem.year}`"
        >
          <div class="date">{{ dateItem.year }}</div>
          <ul
            v-if="dateItem.children && dateItem.children.length"
            class="list"
            :style="{
              height:
                dateItem.children.length % 2 === 0
                  ? `${((dateItem.children.length / 2) * 486 + 110) / 75}rem`
                  : `${
                      (((dateItem.children.length - 1) / 2) * 486 + 456) / 75
                    }rem`,
            }"
          >
            <li
              v-for="item in dateItem.children"
              :key="item.id"
              class="list-item-box"
              @click="handleDetail(item)"
            >
              <div class="list-item">
                <div class="left">
                  <c-image :src="item.image" />
                </div>
                <div class="right">
                  <div class="info">
                    <p class="line-name">
                      {{ item.repertoireName }}
                    </p>
                    <p class="line-time">
                      {{
                        item.showTime
                          ? dayjs(item.showTime).format("YYYY-MM-DD")
                          : ""
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="float-line">
                <div class="dot"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { getTimeLine } from "@/service/performance";
import dayjs from "dayjs";
import SearchInput from "@/components/pc/SearchInput.vue";

export default {
  components: { SearchInput },
  data() {
    return {
      dayjs,
      search: "",
      pager: {
        pageNo: 1,
        pageSize: 99999,
      },
      hasNextPage: true,
      dateList: [],
      timer: null,
      listCount: 0,
    };
  },
  created() {
    this.handleSearch();
  },
  computed: {
    bgClass() {
      if (this.listCount > 8) {
        return "bg-2";
      }
      if (this.listCount > 2) {
        return "bg-1";
      }
      return "";
    },
  },
  methods: {
    async getData() {
      try {
        const res = await getTimeLine({
          ...this.pager,
          condition: { search: this.search },
        });
        if (res.data) {
          const data = res.data.data || [];
          let list = [...this.dateList];

          if (this.pager.pageNo === 1) {
            list = data;
            this.listCount = 0;
          } else {
            list = list.concat(data);
          }
          // 是否有更多
          if (res.data.totalPage <= this.pager.pageSize) {
            this.hasNextPage = false;
            this.listCount = res.data.totalCount;
          } else {
            this.listCount += this.pager.pageSize;
          }
          // 过滤
          let arr = list.map((item) => item.year);
          arr = [...new Set(arr)];
          if (arr.length < list.length) {
            list = arr.map((year) => {
              let children = [];
              list
                .filter((item) => item.year === year)
                .forEach((it) => {
                  children = children.concat(it.children || []);
                });
              return { year, children };
            });
          }
          this.dateList = list;
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.dateList = [];
        }
      }
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      clearTimeout(this.timer);
      if (!this.hasNextPage) return;
      this.timer = setTimeout(() => {
        this.pager.pageNo++;
        this.getData();
      }, 500);
    },
    handleDetail(item) {
      window.open(`#/culture/collection/detail/${item.id}?from=dc`);
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.culture-collection-index-mobile {
  p {
    padding: 0;
    margin: 0;
  }
  .container {
    background-color: #ffffff;
    margin: 0 auto 50/75rem;
    width: 100%;
    padding: 30/75rem;
    box-sizing: border-box;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 100% auto, 100% auto;
    background-position: 0 300/75rem, bottom right;
    &.bg-1 {
      background-image: url("~@/assets/img/performance/bg-2.png");
    }
    &.bg-2 {
      background-image: url("~@/assets/img/performance/bg-2.png"),
        url("~@/assets/img/performance/bg-3.png");
    }
    .title {
      font-size: 50/75rem;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #212122;
      margin: 60/75rem 0 12/75rem;
    }
    .desc {
      font-size: 20/75rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #909399;
      letter-spacing: 3/75rem;
    }
  }
  .date-list {
    margin-top: 40/75rem;
    .date-list-item {
      .date {
        margin: auto;
        width: 230/75rem;
        height: 60/75rem;
        text-align: center;
        line-height: 60/75rem;
        font-size: 20/75rem;
        font-weight: 800;
        color: #ffffff;
        .background-image("/performance/colorbg.png");
        background-size: 100% 100%;
        position: relative;
      }
      & + .date-list-item {
        margin-top: 60/75rem;
        .date::before {
          content: "";
          width: 0/75rem;
          height: 278/75rem;
          border-left: 1/75rem dashed #f9680d;
          position: absolute;
          bottom: 60/75rem;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
        }
      }
    }
  }
  .list {
    padding: 0;
    margin: 30/75rem auto 0;
    list-style: none;
    width: 100%;
    box-sizing: border-box;

    .list-item-box {
      width: 50%;
      position: relative;
      float: left;
      margin-top: 30/75rem;
      display: flex;
      .float-line {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        .dot {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 20/75rem;
          height: 20/75rem;
          border-radius: 20/75rem;
          background: #ffffff;
          border: 1/75rem solid #f9680d;
          z-index: 3;
          &::before {
            content: "";
            width: 0/75rem;
            height: 327/75rem;
            border-left: 1/75rem dashed #f9680d;
            position: absolute;
            bottom: 20/75rem;
            left: 10/75rem;
            z-index: 1;
          }
        }
        &::before {
          content: "";
          width: 7/75rem;
          height: 7/75rem;
          border-radius: 7/75rem;
          background: #f9680d;
          position: absolute;
          bottom: -3/75rem;
          right: 54/75rem;
          z-index: 2;
        }
        &::after {
          content: "";
          width: 44/75rem;
          height: 0/75rem;
          border-top: 1/75rem solid #f9680d;
          position: absolute;
          bottom: 0/75rem;
          right: 10/75rem;
          z-index: 1;
        }
      }
      &:nth-child(1) {
        margin-top: 0;
        .float-line .dot::before {
          height: 258/75rem;
        }
      }
      &:nth-child(2) {
        margin-top: 140/75rem;
      }
      &:nth-child(2n) {
        float: right;
        justify-content: flex-end;
        .float-line {
          left: 0;
          right: unset;
          .dot::before {
            height: 120/75rem;
          }
          &::before {
            left: 54/75rem;
            right: unset;
          }
          &::after {
            left: 10/75rem;
            right: unset;
          }
        }
      }
    }
    .list-item {
      width: 258/75rem;
      height: 456/75rem;
      box-sizing: border-box;
      position: relative;

      .left {
        position: absolute;
        left: 0;
        top: 0;
        width: 238/75rem;
        height: 350/75rem;
        box-shadow: 10/75rem 10/75rem 20/75rem 0/75rem rgba(0, 0, 0, 0.12);
        border-radius: 12/75rem;
        z-index: 2;
        .img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 12/75rem;
        }
      }
      .right {
        position: absolute;
        top: 20/75rem;
        left: 20/75rem;
        height: 436/75rem;
        width: 238/75rem;
        background-color: #f8f8fa;
        .background-image("/performance/bg-1-right.png");
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 101/75rem auto;
        border-radius: 12/75rem;
        .info {
          box-sizing: border-box;
          height: 100%;
          padding: 350/75rem 20/75rem 20/75rem;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .line {
            &-name {
              font-size: 26/75rem;
              font-weight: 800;
              color: #212122;
              .text-overflow(1);
            }
            &-time {
              font-size: 20/75rem;
              font-weight: 400;
              color: #909399;
            }
          }
        }
      }
    }
  }
}
</style>
